@font-face {
  font-family: Averta;
  src: local(Averta), url(../../../fonts/Averta/Averta.otf);
}
@font-face {
  font-family: AvertaRegular;
  src: local(AvertaRegular), url(../../../fonts/Averta/AvertaDemo-Regular.otf);
}
@font-face {
  font-family: AvertaThin;
  src: local(AvertaThin), url(../../../fonts/Averta/Averta\ Thin.otf);
}
@font-face {
  font-family: AvertaLight;
  src: local(AvertaLight), url(../../../fonts/Averta/Averta\ Light.otf);
}
@font-face {
  font-family: AvertaBold;
  src: local(AvertaBold), url(../../../fonts/Averta/Averta\ Bold.otf);
}
@font-face {
  font-family: AvertaSemiBold;
  src: local(AvertaSemiBold), url(../../../fonts/Averta/Averta\ Semibold.otf);
}
@font-face {
  font-family: AvertaLightItalic;
  src: local(AvertaLightItalic),
    url(../../../fonts/Averta/Averta\ Light\ Italic.otf);
}
@font-face {
  font-family: AvertaSemiBoldItalic;
  src: local(AvertaSemiBoldItalic),
    url(../../../fonts/Averta/Averta\ Semibold\ Italic.otf);
}
.aboutus body,
.resp-body #root {
  font-size: 12px;
  line-height: 26px;
  color: #1b2e4f;
  letter-spacing: 0.5px;
}
.aboutus p {
  margin-bottom: 30px;
}
.aboutus #banner_content {
  margin-top: 0 !important;
  margin-bottom: 30px;
}
.aboutus h1,
.aboutus h2,
.aboutus h3,
.aboutus h4,
.aboutus h5,
.aboutus h6,
.aboutus .h1,
.aboutus .h2,
.aboutus .h3,
.aboutus .h4,
.aboutus .h5,
.aboutus .h6 {
  color: #1b2e4f;
  font-weight: 700;
  text-transform: capitalize;
}
.aboutus h1,
.aboutus .h1 {
  font-size: 70px;
  line-height: 90px;
  margin-bottom: 30px;
}
.aboutus .h2,
.aboutus h2 {
  font-size: 40px;
  line-height: 50px;
  color: #000000 !important;
}
.aboutus h3,
.aboutus .h3 {
  font-size: 45px;
  line-height: 55px;
  margin-bottom: 20px;
}
.aboutus h4,
.aboutus .h4 {
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 15px;
}
p > a {
  color: inherit;
}
.row {
  margin: 0 -15px !important;
}
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p {
  color: #fff;
}
.bg-dark {
  background-color: #000 !important;
}
.aboutus section {
  padding: 80px 0 50px;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.aboutus {
  background: #fff;
}
.wow {
  visibility: hidden;
}
.note-editable .wow {
  visibility: visible;
}
.aboutus #banner_content.bannerHomepage {
  margin-top: 0 !important;
}
.aboutus a:hover {
  text-decoration: none;
}
.aboutus .homepage a {
  color: #cccccc;
  font-family: AvertaLight;
  font-size: 14px;
  line-height: 17px;
}
.aboutus i.fa.fa-circle {
  font-size: 7px;
  margin: 5px;
}
.aboutus .about-img .img-wrapper {
  min-height: 500px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.aboutus .aboutus-content-box .img-wrapper {
  min-height: 500px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.aboutus .para-content p {
  font-family: AvertaRegular;
  font-size: 16px;
  line-height: 30px;
}

.aboutus .img-wrapper {
  width: 100%;
  height: 600px;
}

.aboutus .aboutus-head-img {
  width: 100%;
  background-image: url("../../../img/Image61@3x.png");
  background-size: cover;
}
.aboutus .aboutus-content-img {
  background-image: url("../../../img/aboutus.png");
}
.aboutus .content-box h2 {
  font-family: AvertaBold;
  font-size: 50px;
  line-height: 60px;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .aboutus .hero-section-aboutus {
    padding-top: 150px;
  }
}
@media (max-width: 991px) {
}
@media (max-width: 767px) {
}
@media (max-width: 575px) {
}
