@font-face {
  font-family: Averta;
  src: local(Averta), url(../../../fonts/Averta/Averta.otf);
}
@font-face {
  font-family: AvertaRegular;
  src: local(AvertaRegular), url(../../../fonts/Averta/AvertaDemo-Regular.otf);
}
@font-face {
  font-family: AvertaThin;
  src: local(AvertaThin), url(../../../fonts/Averta/Averta\ Thin.otf);
}
@font-face {
  font-family: AvertaLight;
  src: local(AvertaLight), url(../../../fonts/Averta/Averta\ Light.otf);
}
@font-face {
  font-family: AvertaBold;
  src: local(AvertaBold), url(../../../fonts/Averta/Averta\ Bold.otf);
}
@font-face {
  font-family: AvertaSemiBold;
  src: local(AvertaSemiBold), url(../../../fonts/Averta/Averta\ Semibold.otf);
}
@font-face {
  font-family: AvertaLightItalic;
  src: local(AvertaLightItalic),
    url(../../../fonts/Averta/Averta\ Light\ Italic.otf);
}
@font-face {
  font-family: AvertaSemiBoldItalic;
  src: local(AvertaSemiBoldItalic),
    url(../../../fonts/Averta/Averta\ Semibold\ Italic.otf);
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #000000;
  font-weight: 700;
  text-transform: capitalize;
  font-family: AvertaBold;
}
.contactus h1,
.contactus .h1 {
  font-size: 70px;
  line-height: 90px;
  margin-bottom: 30px;
  font-family: AvertaBold;
}
.contactus h2,
.contactus .h2 {
  font-size: 50px;
  line-height: 60px;
  color: #000000 !important;
  font-family: AvertaBold;
}
.contactus h3,
.contactus .h3 {
  font-size: 45px;
  line-height: 55px;
  margin-bottom: 20px;
  font-family: AvertaBold;
}
.contactus h4,
.contactus .h4 {
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 15px;
  font-family: AvertaBold;
}
.contactus h6,
.contactus .h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #cccccc;
  font-family: AvertaBold;
}
p,
.form-control {
  font-family: Averta;
}
p > a {
  color: inherit;
}
.row {
  margin: 0 -15px !important;
}
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p {
  color: #fff;
}
.bg-dark {
  background-color: #000 !important;
}
.contactus section {
  padding: 80px 0 0px;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.contactus .contact-section {
  padding: 40px 0 25px;
}
.page-wrapper {
  background: #fff;
}
.contactus .form-box {
  margin-bottom: 20px;
}
.contactus .form-control {
  background: #f5f5f5;
  border: #f5f5f5;
  color: #000000;
  margin: 0;
}
.contactus .form-box i.fa {
  position: absolute;
  color: #cfcfcf;
  right: 25px;
  z-index: 1;
  top: 10px;
  font-size: 15px;
}
.contactus .icons {
  padding: 20px;
  margin: 10px;
  background: #000;
  color: #ffffff;
}
.contactus .icons a {
  color: #fff;
}
.contactus .icons a:hover {
  color: #fff;
}
.contactus .icons i.fa {
  font-size: 25px;
}
.wow {
  visibility: hidden;
}
.note-editable .wow {
  visibility: visible;
}
.page-wrapper #banner_content.bannerHomepage {
  margin-top: 0 !important;
}
.contactus .content-box i.fa {
  font-size: 32px;
  line-height: 42px;
  margin-right: 15px;
  color: #ffffff;
}
.contactus .content-box p {
  font-family: AvertaRegular;
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
}
.contactus .middle-section {
  padding: 40px 0 25px;
  background-color: #000000;
}
.contactus .contacts-head a {
  color: #cccccc;
  font-family: AvertaLight;
  font-size: 14px;
  line-height: 17px;
}
.contactus i.fa.fa-circle {
  font-size: 7px;
  margin: 5px;
}
.contactus .head-box h6 {
  font-family: AvertaLight;
  font-size: 14px;
  line-height: 20px;
}
.contactus .head-box h2 {
  font-family: AvertaSemiBold;
  font-size: 30px;
  line-height: 40px;
}
.contactus .btn {
  padding: 5px 20px;
  font-family: AvertaRegular;
}
.contactus .btn-light {
  background: transparent;
  border-radius: 0;
  font-size: 14px;
  color: #000;
  border: 1px solid #000000;
}
.contactus .btn-light:hover {
  background-color: #000000;
  color: #ffffff;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .contactus .hero-section-contactus {
    padding-top: 150px;
  }
}
@media (max-width: 991px) {
}
@media (max-width: 767px) {
}
@media (max-width: 575px) {
}

.contact-icon {
  height: 40px;
  padding: 5px 10px;
  background-color: #212529;
  color: #ffffff;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.contact-icon:hover,
.contact-icon:focus {
  color: #ffffff;
}
