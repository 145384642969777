@font-face {
  font-family: Averta;
  src: local(Averta), url(../../../fonts/Averta/Averta.otf);
}
@font-face {
  font-family: AvertaRegular;
  src: local(AvertaRegular), url(../../../fonts/Averta/AvertaDemo-Regular.otf);
}
@font-face {
  font-family: AvertaThin;
  src: local(AvertaThin), url(../../../fonts/Averta/Averta\ Thin.otf);
}
@font-face {
  font-family: AvertaLight;
  src: local(AvertaLight), url(../../../fonts/Averta/Averta\ Light.otf);
}
@font-face {
  font-family: AvertaBold;
  src: local(AvertaBold), url(../../../fonts/Averta/Averta\ Bold.otf);
}
@font-face {
  font-family: AvertaSemiBold;
  src: local(AvertaSemiBold), url(../../../fonts/Averta/Averta\ Semibold.otf);
}
@font-face {
  font-family: AvertaLightItalic;
  src: local(AvertaLightItalic),
    url(../../../fonts/Averta/Averta\ Light\ Italic.otf);
}
@font-face {
  font-family: AvertaSemiBoldItalic;
  src: local(AvertaSemiBoldItalic),
    url(../../../fonts/Averta/Averta\ Semibold\ Italic.otf);
}
.all-products body,
.resp-body #root {
  font-size: 12px;
  line-height: 26px;
  color: #000000;
  letter-spacing: 0.5px;
}
.all-products p {
  margin-bottom: 30px;
}
.all-products #banner_content {
  margin-top: 0 !important;
  margin-bottom: 30px;
}
.all-products h1,
.all-products h2,
.all-products h3,
.all-products h4,
.all-products h5,
.all-products h6,
.all-products .h1,
.all-products .h2,
.all-products .h3,
.all-products .h4,
.all-products .h5,
.all-products .h6 {
  color: #000000;
  font-weight: 100;
  text-transform: none;
}
.all-products h1,
.all-products .h1 {
  font-size: 70px;
  line-height: 90px;
  margin-bottom: 30px;
}
.all-products h2,
.all-products .h2 {
  font-size: 50px;
  line-height: 60px;
  margin-bottom: 20px;
  font-family: AvertaBold;
}
.all-products h3,
.all-products .h3 {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 20px;
}
.all-products h4,
.all-products .h4 {
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 15px;
}
.all-products h6,
.all-products .h6 {
  font-size: 16px;
  line-height: 34px;
  margin-bottom: 10px;
  font-family: AvertaRegular;
  font-family: 500;
}
.all-products p > a {
  color: inherit;
}
.all-products .row {
  margin: 0 -15px !important;
}
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p {
  color: #fff;
}
.bg-dark {
  background-color: #000 !important;
}
.all-products section {
  padding: 80px 0 50px;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.all-products {
  background: #fff;
}
.wow {
  visibility: hidden;
}
.note-editable .wow {
  visibility: visible;
}
.all-products #banner_content.bannerHomepage {
  margin-top: 0 !important;
}
.all-products .btn {
  padding: 5px 50px;
  border-radius: 0;
  font-family: AvertaRegular;
}
.all-products .btn-secondary {
  background: #000;
}
.all-products .btn-light {
  background: transparent;
  font-size: 14px;
  color: #000;
  border: 1px solid #000000;
}
.all-products .btn-light:hover {
  background-color: #000000;
  color: #ffffff;
}
.all-products .head-dropdown p {
  margin-bottom: 5px;
  font-size: 18px;
  font-family: AvertaRegular;
}
.all-products button#dropdownMenuButton {
  text-align: left;
  background-color: #f5f5f5;
}
.all-products .dropdown-toggle::after {
  text-align: right;
  float: right;
  align-items: center;
  margin-top: 10px;
}

.all-products .accordion {
  padding: 70px;
  background: #000000;
}
.all-products div#accordionExample .card,
.all-products div#accordionExample2 .card {
  background: #000000;
  color: #ffffff;
}
.all-products .card-header h2 .btn-link {
  font-size: 30px;
  line-height: 50px;
  font-weight: 700;
  color: #ffffff;
  font-family: AvertaSemiBold;
  padding: 0;
}
.all-products .card-header {
  border-left: 2px solid #141414;
}
.all-products .card-header .btn-link.focus,
.all-products .btn-link:focus {
  text-decoration: none;
}
.all-products .card .show {
  border-left: 2px solid #ffffff;
  transition: ease-in;
}
.all-products .card-header h2 .btn-link:hover {
  text-decoration: none;
}
.all-products .category-section .img-wrapper {
  min-height: 500px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.all-products .marble-section .img-wrapper {
  min-height: 300px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.all-products .granite-section .img-wrapper {
  min-height: 600px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.all-products a.underline {
  font-family: AvertaRegular;
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 30px;
  color: #fff;
}
.all-products .content-box h2 {
  font-family: AvertaSemiBold;
  font-size: 30px;
  line-height: 60px;
}
/* .all-products .subcat {
  font-family: AvertaRegular;
  font-size: 16px !important;
  line-height: 50px;
  color: #7c7c7c !important;
} */
.all-products a.underline:hover {
  text-decoration: none;
  cursor: pointer;
}
.all-products .underline {
  display: inline;
  position: relative;
  overflow: hidden;
}
.all-products .underline:after {
  content: "";
  position: absolute;
  right: 0;
  width: 0;
  bottom: -5px;
  background: #fff;
  height: 2px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.all-products .underline:hover:after,
.all-products .underline:focus:after,
.all-products .underline:active:after {
  left: 0;
  right: 50%;
  width: 50%;
}
.all-products .line:after {
  width: 45px;
  border: 1px solid #848484;
  height: 1px;
  content: "";
  top: 15px;
  left: 14%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%) rotate(360deg);
  margin: 0 auto;
}
.all-products .card-body p {
  font-family: AvertaLight;
  font-size: 14px;
  line-height: 1.71;
}
.all-products .ml-60 {
  margin-left: 60px;
}
.all-products p.section-head {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  font-family: AvertaLight;
}
.all-products .content-box {
  background: #000000;
  padding: 100px 70px;
}
.all-products .content-box p {
  font-family: AvertaLight;
  font-size: 14px;
  line-height: 24px;
}
.all-products .collapsed {
  font-family: AvertaRegular !important;
  font-size: 14px !important;
  line-height: 60px !important;
  color: #7c7c7c !important;
}

.all-products .category-section {
  z-index: 1;
  position: relative;
}

.all-products .category-section,
.all-products .marble-section,
.all-products .granite-section {
  border-top: 1px solid #d0d0d0;
}
.all-products .allproducts-img1 {
  /* background-image: url("../../../img/Image9@3x.png"); */
}
.all-products .allproducts-img2 {
  background-image: url("../../../img/Image11@3x.png");
}
.all-products .allproducts-img3 {
  background-image: url("../../../img/MaskGroup7@3x.png");
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .all-products .hero-section-allproducts {
    padding-top: 150px;
    position: relative;
    z-index: 2;
  }
}
@media (max-width: 991px) {
}
@media (max-width: 767px) {
  .all-products h2,
  .all-products .h2 {
    font-size: 28px;
    line-height: 40px;
  }
  .all-products .accordion {
    padding: 20px;
  }
  .all-products .card-header h2 .btn-link {
    font-size: 22px;
    line-height: 32px;
    text-align: left;
  }
  .all-products .card-body p {
    font-size: 14px;
    line-height: 30px;
  }
  .all-products .category-section .img-wrapper {
    min-height: 300px;
  }
  .all-products .granite-section .img-wrapper {
    min-height: 300px;
  }
  .all-products .content-box {
    background: #000000;
    padding: 50px 35px;
  }
}
@media (max-width: 575px) {
}
