@font-face {
  font-family: Averta;
  src: local(Averta), url(../../../fonts/Averta/Averta.otf);
}
@font-face {
  font-family: AvertaRegular;
  src: local(AvertaRegular), url(../../../fonts/Averta/AvertaDemo-Regular.otf);
}
@font-face {
  font-family: AvertaThin;
  src: local(AvertaThin), url(../../../fonts/Averta/Averta\ Thin.otf);
}
@font-face {
  font-family: AvertaLight;
  src: local(AvertaLight), url(../../../fonts/Averta/Averta\ Light.otf);
}
@font-face {
  font-family: AvertaBold;
  src: local(AvertaBold), url(../../../fonts/Averta/Averta\ Bold.otf);
}
@font-face {
  font-family: AvertaSemiBold;
  src: local(AvertaSemiBold), url(../../../fonts/Averta/Averta\ Semibold.otf);
}
@font-face {
  font-family: AvertaLightItalic;
  src: local(AvertaLightItalic),
    url(../../../fonts/Averta/Averta\ Light\ Italic.otf);
}
@font-face {
  font-family: AvertaSemiBoldItalic;
  src: local(AvertaSemiBoldItalic),
    url(../../../fonts/Averta/Averta\ Semibold\ Italic.otf);
}
.allproductslist body,
.resp-body #root {
  font-size: 12px;
  line-height: 26px;
  color: #000000;
  letter-spacing: 0.5px;
}
.allproductslist p {
  margin-bottom: 30px;
}
.allproductslist #banner_content {
  margin-top: 0 !important;
  margin-bottom: 30px;
}
.allproductslist h1,
.allproductslist h2,
.allproductslist h3,
.allproductslist h4,
.allproductslist h5,
.allproductslist h6,
.allproductslist .h1,
.allproductslist .h2,
.allproductslist .h3,
.allproductslist .h4,
.allproductslist .h5,
.allproductslist .h6 {
  color: #000000;
  font-weight: 700;
  text-transform: capitalize;
}
.allproductslist h1,
.allproductslist .h1 {
  font-size: 70px;
  line-height: 90px;
  margin-bottom: 30px;
}
.allproductslist h3,
.allproductslist .h3 {
  font-size: 45px;
  line-height: 55px;
  margin-bottom: 20px;
}
.allproductslist h4,
.allproductslist .h4 {
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 15px;
}
.allproductslist h6,
.allproductslist .h6 {
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  font-family: Averta;
}
.allproductslist p > a {
  color: inherit;
}
.allproductslist .row {
  margin: 0 -15px !important;
}
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p {
  color: #fff;
}
.bg-dark {
  background-color: #000 !important;
}
.allproductslist section {
  padding: 80px 0 50px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.allproductslist {
  background: #fff;
}
.wow {
  visibility: hidden;
}
.note-editable .wow {
  visibility: visible;
}
.allproductslist #banner_content.bannerHomepage {
  margin-top: 0 !important;
}
.allproductslist .head-dropdown p {
  font-family: AvertaRegular;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}
.allproductslist button#dropdownMenuButton {
  text-align: left;
  background-color: #f5f5f5;
}
.allproductslist .dropdown-toggle::after {
  text-align: right;
  float: right;
  align-items: center;
  margin-top: 10px;
}
.allproductslist .btn {
  font-family: AvertaRegular;
  padding: 5px 50px;
  border-radius: 0;
}
.allproductslist .btn-secondary {
  background: #000;
}
.allproductslist .btn-light {
  background: transparent;
  color: #fff;
}
.allproductslist .btn-light:hover {
  background-color: #ffffff;
  border-color: #ffffff;
}
.allproductslist .products-section {
  position: relative;
  border-top: 1px solid #eeeeee;
}
.allproductslist .products-section .product-box img {
  margin-top: -50px;
}
.allproductslist .product-box {
  background: #eeeeee;
  position: relative;
  padding: 25px;
  margin-bottom: 60px;
  transition: 250ms ease-in-out;
}

.allproductslist .product-box:hover {
  transform: translateY(-20px) scale(1.03);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
  background: #fefefe;
  cursor: pointer;
}

.allproductslist .content-detail-inner a {
  font-size: 18px;
  color: #000;
  text-align: left;
  line-height: 40px;
}
.allproductslist .content-detail-inner a:hover {
  text-decoration: none;
}
.allproductslist .underline {
  font-family: AvertaRegular;
  display: inline;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.modalpopupproducts .swiper-button-prev,
.swiper-button-next {
  color: #000000 !important;
}
.modalpopupproducts .swiper-pagination-bullet {
  width: 25px;
  height: 5px;
  display: inline-block;
  border-radius: 0;
  background-color: #ffffff;
  opacity: 1;
}
.modalpopupproducts
  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #000000;
}
.allproductslist .underline:after {
  content: "";
  position: absolute;
  right: 0;
  width: 0;
  bottom: -5px;
  background: #000000;
  height: 2px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.allproductslist .underline:hover:after,
.allproductslist .underline:focus:after,
.allproductslist .underline:active:after {
  left: 0;
  right: 50%;
  width: 50%;
}
.allproductslist p.first-content {
  font-family: AvertaRegular;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 26px;
  color: #a2a3a2;
}
.allproductslist p.second-content {
  font-family: AvertaLight;
  font-size: 10px;
  line-height: 20px;
  margin-bottom: 0;
  color: #a2a3a2;
}
.allproductslist p.third-content {
  font-family: AvertaSemiBold;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding: 0;
}
.modalpopup .modal-header {
  border: none;
  height: 10px;
  margin: 0;
}
.modalpopup .modal-content-box h5 {
  font-family: AvertaSemiBold;
  font-size: 30px;
  line-height: 60px;
}
.modalpopup .modal-content-box .first-content {
  font-family: AvertaRegular;
  font-size: 16px;
  line-height: 28px;
  color: #a2a3a2;
}
.modalpopup .modal-content-box p {
  font-family: AvertaLight;
  font-size: 16px;
  line-height: 30px;
}
.modalpopup p.second-content {
  font-size: 10px !important;
  color: #a2a3a2;
  margin-bottom: 0px;
}

#img-preview-container {
  display: grid;
  place-items: center;
}

.modalpopupproducts .box-section {
  width: 100%;
  cursor: pointer;
}

.modalpopupproducts .box-section #imgFluid {
  width: 100px;
  height: 100px;
  min-width: 100px !important;
  min-height: 100px !important;
  object-fit: cover !important;
  transition: 0.1s linear all;
}

.modalpopupproducts .box-section #imgFluid:hover {
  outline: 5px solid #555;
}

.outline-1 {
  outline: 5px solid #555;
  transition: 0.25s ease-in-out all;
}

.third-gallery .swiper-wrapper {
  padding: 10px 30px 50px 30px;
}

.third-gallery .swiper-slide {
  display: table;
}

.swiper-container {
  overflow: -moz-hidden-unscrollable !important;
}

.modalpopup .third-gallery {
  max-width: 90%;
}
.modalpopup .box-section:hover h5,
.modalpopup .box-section:hover p {
  color: #ffffff;
}
.modalpopup .box-section p {
  font-family: AvertaRegular;
  font-size: 14px;
  padding: 0;
  margin: 0;
}
.modalpopup .thumb-gallery {
  max-width: 80%;
}
.modalpopup .modal-content-box p.third-content {
  font-family: AvertaSemiBold;
  font-size: 20px !important;
}
.modalpopup p.third-content {
  font-weight: 700;
}
.modalpopup .modal-underline {
  margin-top: 30px;
  margin-bottom: 30px;
}
.modalpopupproducts .btn-light {
  font-family: AvertaRegular;
  font-size: 16px;
}
.modalpopup .box-section h5 {
  font-family: AvertaSemiBold;
  font-size: 20px;
}

.swiper-container {
  margin-left: 0;
}

.swiper-slide {
  margin-top: 20px;
}

.modalpopup .swiper-slide {
  text-align: center;
}
.allproductslist .homepage a {
  color: #cccccc;
  font-family: AvertaLight;
  font-size: 14px;
  line-height: 17px;
}
.allproductslist i.fa.fa-circle {
  font-size: 7px;
  margin: 5px;
}
.allproductslist .content-box h2 {
  font-family: AvertaBold;
  font-size: 50px;
  line-height: 50px;
}
.allproductslist .head-content h6 {
  font-size: 16px;
  line-height: 34px;
  font-family: AvertaRegular;
}
.allproductslist .product-box-content h5 {
  font-family: AvertaSemiBold;
  font-size: 22px;
  line-height: 40px;
}
@media (max-width: 576px) {

  #colorSelect, #materialSelect, #typeSelect {
    padding-bottom: 30px;
  }

}
@media (min-width: 768px) {
  .allproductslist .modal {
    top: 5% !important;
  }
  .thumb-gallery {
    margin-top: -50px;
    margin-bottom: 35px;
  }
}
@media (min-width: 992px) {
  .modalpopupproducts .modal-img {
    min-width: 400px;
    min-height: 400px;
  }
  .modalpopup {
    max-width: 1200px !important;
  }
  .allproductslist .hero-section-allProductsList {
    padding-top: 150px;
  }
}
@media (max-width: 991px) {
  .modalpopupproducts .modal-img {
    min-width: 200px;
    min-height: 200px;
  }
  .modalpopup {
    max-width: 1000px !important;
  }
}

@media (max-width: 767px) {
  .allproductslist .head-dropdown p {
    font-size: 14px;
    margin-top: 20px;
  }
}

input {
  outline: none;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  font-family: inherit;
  font-size: 100%;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type="search"] {
  background: #ededf0 url("../../../img/searchicon.png") 12px no-repeat;
  border: solid 1px #eeeeee;
  padding: 10px 0px 10px 32px !important;
  width: 55px;
  border-radius: 0 !important;
  width: 55px;
  border-radius: 10em;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
input[type="search"]:focus {
  width: 130px;
  background-color: #fff;
  border-color: #66cc75;

  -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  -moz-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
}

input:-moz-placeholder {
  color: #eeeeee;
}
input::-webkit-input-placeholder {
  color: #eeeeee;
}
#search-box-expand input[type="search"] {
  width: 15px;
  padding-left: 10px;
  color: transparent;
  cursor: pointer;
}
#search-box-expand input[type="search"]:hover {
  background-color: #fff;
}
#search-box-expand input[type="search"]:focus {
  width: 150px;
  padding-left: 32px;
  color: #000;
  background-color: #fff;
  cursor: auto;
}
#search-box-expand input:-moz-placeholder {
  color: transparent;
}
#search-box-expand input::-webkit-input-placeholder {
  color: transparent;
}

@media(min-width: 700px) and (max-width: 900px) {
  .HomePage .testimonial-section .head-content {
    position: relative;
  }

  .quote-open {
    top: 25%;
    left: 30px;
  }

  .quote-close {
    top: 88%;
    right: 40px;
  }

  .nav-item {
    color: #000;
  }

  #quoteLink, #signInLink {
    color: #000;
    border: 0.5px solid #000;
    margin: 20px;
    padding: 10px;
    text-align: center;
  }

  .header-page .navbar-container .navbar-toggler {
    border: 1px solid #000;
    position: absolute;
    top: 20px;
    right: 50px;
  }

  .header-page .navbar-container .navbar-toggler .navbar-toggle-icon {
    color: #000;
  }

  .white-toggle {
    border: 1px solid #fff !important;
  }

  .white-toggle-icon {
    color: #fff !important;
  }

  .login-btn {
    padding: 20px 0;
  }

  .header-page .navbar-container {
    display: block;
  }

  .header-page .navbar-container .nav-item .nav-link:hover {
    color: #000;
  }

  .hero-section-allproducts {
    position: relative;
    z-index: 1;
  }

  #colorSelect, #materialSelect, #typeSelect {
    position: relative;
    z-index: 2;
  }

  .all-products .category-section, .all-products .marble-section, .all-products .granite-section {
    position: relative;
    z-index: 1;
  }

  .all-products section {
    z-index: 2;
  }
}
