.signuppage .modal-header {
  display: block !important;
}
.signuppage .modal-header .close {
  position: absolute;
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  top: 1%;
  right: 2%;
}
.signuppage .modal-body {
  padding: 0 !important;
}
.modal-body .signin-tab {
  border-bottom: 1px solid #000000;
}
.modal-body .signin-btn {
  background: #000000;
  padding: 15px 20px;
}
.modal-body a:hover {
  text-decoration: none;
}
.modal-body .signup-btn {
  padding: 15px 20px;
}
.modal-body .signin-btn a {
  color: #ffffff;
}
.modal-body .signup-btn a {
  color: #000000;
}
.modal-body .form-box {
  position: relative;
}
.modal-body .form-box i.fa {
  font-size: 16px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  margin: 0 auto;
  color: #cfcfcf;
}
.modal-body .form-control {
  background: #f5f5f5;
  border: #f5f5f5;
}
.modal-body .rememberme {
  color: #a2a3a2 !important;
}
.modal-body .frg-pwd a {
  color: #000000;
}
.modal-body .frg-pwd a:hover {
  color: #000000;
  text-decoration: none;
}
.modal {
  top: 0% !important;
}
.modal .btn {
  padding: 5px 50px;
}
.modal .btn-light {
  background: transparent;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 0;
}
.modal .btn-light:hover {
  background-color: #000000;
  border: 1px solid #000000;
  color: #ffffff;
}
.notmember a {
  color: #000000;
  font-weight: 600;
}
.confirmationmsg {
  font-size: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.resendotp {
  color: #000000;
}
.resendotp:hover {
  color: #000000;
  text-decoration: none;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.otp {
  width: 10% !important;
  margin: 10px;
  text-align: center;
}
.nav-item.dropdown a {
  color: #000000;
  cursor: pointer;
}
.valid {
  color: #009838;
}
.successicon {
  max-width: 50px;
  max-height: 50px;
}
.modal-body .nav {
  display: contents !important;
  text-align: center !important;
}
.modal-body .nav-tabs .nav-item.show .nav-link,
.modal-body .nav-tabs .nav-link.active {
  border: none;
  background: #000000;
  color: #ffffff;
  padding: 10px 30px;
  margin: 0;
  border-radius: 0;
}
.modal-body .nav-item {
  margin: 0;
  padding: 0;
}
.modal-body .nav-tabs .nav-link:focus,
.modal-body .nav-tabs .nav-link:hover {
  border: none;
}
.modal-body .bdr-btm {
  border-bottom: 1px solid #000000;
}
.modal-body .bdr-btm a {
  color: #000000;
  text-decoration: none;
}
.modal-body .bdr-btm a:hover {
  color: #000000;
  text-decoration: none;
}

@media (max-width: 567px) {
  .otp {
    width: 15% !important;
  }
  .modal {
    top: 0 !important;
  }
  .signin .nav-link,
  .quote .nav-link {
    padding: 10px 20px;
    text-align: center;
  }
}