@font-face {
  font-family: Averta;
  src: local(Averta), url(../../../fonts/Averta/Averta.otf);
}
@font-face {
  font-family: AvertaRegular;
  src: local(AvertaRegular), url(../../../fonts/Averta/AvertaDemo-Regular.otf);
}
@font-face {
  font-family: AvertaThin;
  src: local(AvertaThin), url(../../../fonts/Averta/Averta\ Thin.otf);
}
@font-face {
  font-family: AvertaLight;
  src: local(AvertaLight), url(../../../fonts/Averta/Averta\ Light.otf);
}
@font-face {
  font-family: AvertaBold;
  src: local(AvertaBold), url(../../../fonts/Averta/Averta\ Bold.otf);
}
@font-face {
  font-family: AvertaSemiBold;
  src: local(AvertaSemiBold), url(../../../fonts/Averta/Averta\ Semibold.otf);
}
@font-face {
  font-family: AvertaLightItalic;
  src: local(AvertaLightItalic),
    url(../../../fonts/Averta/Averta\ Light\ Italic.otf);
}
@font-face {
  font-family: AvertaSemiBoldItalic;
  src: local(AvertaSemiBoldItalic),
    url(../../../fonts/Averta/Averta\ Semibold\ Italic.otf);
}
.HomePage .info-content-box h6 {
  font-family: AvertaSemiBold;
}
.HomePage body,
.resp-body #root {
  font-size: 12px;
  line-height: 26px;
  color: #000000;
  letter-spacing: 0.5px;
  font-family: Averta;
}
.ft-regular {
  font-family: AvertaRegular !important;
}
.HomePage p {
  margin-bottom: 30px;
}
.HomePage #banner_content {
  margin-top: 0 !important;
  margin-bottom: 30px;
}
.HomePage h1,
.HomePage h2,
.HomePage h3,
.HomePage h4,
.HomePage h5,
.HomePage h6,
.HomePage .h1,
.HomePage .h2,
.HomePage .h3,
.HomePage .h4,
.HomePage .h5,
.HomePage .h6 {
  color: #000000;
  font-weight: 700;
  text-transform: capitalize;
  font-family: AvertaBold;
}
.HomePage h1,
.HomePage .h1 {
  font-size: 70px;
  line-height: 90px;
  margin-bottom: 30px;
  font-family: AvertaBold;
}
.HomePage h2,
.HomePage .h2 {
  font-size: 50px;
  line-height: 55px;
  margin-bottom: 20px;
  font-family: AvertaBold;
}
.HomePage h3,
.HomePage .h3 {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 20px;
  font-family: AvertaBold;
}
.HomePage h4,
.HomePage .h4 {
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 15px;
  font-family: AvertaBold;
}
.HomePage h6,
.HomePage .h6 {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
  font-weight: 400;
}
.HomePage p {
  font-size: 12px;
  margin-bottom: 30px;
  line-height: 18px;
  text-align: justify;
  font-family: Averta;
}
.HomePage p > a {
  color: inherit;
}
.HomePage .row {
  margin: 0 -15px !important;
}
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p {
  color: #fff;
}
.bg-dark {
  background-color: #000 !important;
}
.HomePage section {
  padding: 0;
  position: relative;
}
.HomePage .page-wrapper {
  background: #fff;
}
.HomePage .wow {
  visibility: hidden;
}
.HomePage .note-editable .wow {
  visibility: visible;
}
.HomePage .page-wrapper #banner_content.bannerHomepage {
  margin-top: 0 !important;
}

/* Bootstrap Carousel Styles */

.carousel-item {
  height: 100vh;
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition: opacity .7s ease-in-out;
}
.carousel-fade .carousel-item.active {
  opacity: 1;
}

.carousel-caption > p {
  display: none;
}

.carousel-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.55);
  width: 100%;
  height: 100%;
}

.carousel-item img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: bottom;
}

.carousel-caption {
  margin-top: -30px;
}

.carousel-caption h3 {
  font-size: 20px;
  font-weight: normal;
  font-family: AvertaRegular;
  color: #fff;
  opacity: 0.5;
  text-align: right;
}

.indicators-custom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 0;
  bottom: 70px;
}

.indicators > span {
  display: inline-block;
  margin: 0 20px;
}

.caption-line {
  display: inline-block;
  width: 2px;
  height: 43px;
  background-color: #fff;
  margin: 0 20px;
}

.caption-line-1 {
  display: inline-block;
  margin-left: 20px;
}

/* End of Bootstrap Carousel Styles */

.HomePage .hero-content {
  position: absolute;
  bottom: 7%;
  left: 10%;
  z-index: 2;
}

.HomePage .head-dropdown {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  z-index: 2;
  background: #ffffff;
  max-width: 400px;
  width: 100%;
  padding: 20px 25px;
  border-radius: 5px;
}

#productSearchHeader {
  position: relative;
  color: #a2a3a2;
  text-transform: uppercase;
  font-size: 14px;
  font-family: AvertaLight;
  letter-spacing: 1.4px;
  font-weight: 300;
  text-align: center;
  padding: 0 0 15px 0;
  margin: 0 0 40px 0;
}

#productSearchHeader::before {
  content: " ";
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 1px;
  background: #000000;
  opacity: 0.5;
}

.HomePage .head-dropdown p {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  font-family: AvertaRegular;
}

.HomePage .dropdown {
  padding: 5px 0 30px 0;
}
.HomePage button#dropdownMenuButton {
  text-align: left;
  background-color: #f5f5f5;
}
.HomePage .dropdown-toggle::after {
  text-align: right;
  float: right;
  align-items: center;
  margin-top: 10px;
}

.HomePage .btn {
  padding: 5px 30px;
  border-radius: 0;
  font-family: AvertaRegular;
}
.HomePage .btn-secondary {
  background: #000;
}
.HomePage .btn-light {
  background: transparent;
  color: #fff;
}
.HomePage .btn-light:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #000000 !important;
}
.HomePage .btn-viewproduct {
  background: transparent;
  color: #000;
  border: 1px solid #000000;
  border-radius: 0;
}
.HomePage .btn-viewproduct:hover {
  background-color: #000000;
  color: #ffffff;
}
.HomePage .categories-section {
  overflow: hidden;
}
.HomePage .categories-section p.section-head,
.HomePage .testimonial-section p.section-head {
  font-size: 20px;
  line-height: 30px;
  font-family: AvertaLight;
}
.HomePage .img1 {
  background-size: cover !important;
  min-width: 400px !important;
  background-repeat: no-repeat;
  max-height: 480px !important;
  object-fit: cover;
}
.HomePage .categories-section .line:after {
  width: 60px;
  border: 0.2px solid rgb(0, 0, 0, 0.3);
  height: 1px;
  content: "";
  top: 88px;
  left: 24%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%) rotate(360deg);
  margin: 0 auto;
}
.HomePage .testimonial-section .line:after {
  width: 40px;
  border: 1px solid #8c9090;
  height: 1px;
  content: "";
  top: 15px;
  left: 15%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%) rotate(360deg);
  margin: 0 auto;
}
.HomePage .categories-section .content-box {
  left: 5%;
}
.HomePage .testimonial-section .content-box {
  left: 10%;
}
/* .HomePage .categories-section .content-box, */
.HomePage .testimonial-section .content-box {
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}
.HomePage .categories-box {
  position: relative;
}
.HomePage .categories-box-inner {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
}
.HomePage .categories-box-inner .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.HomePage .categories-box-inner:hover .content-overlay {
  opacity: 1;
}
.HomePage .content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.HomePage .categories-box-inner:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}
.HomePage .content-details .inner-link {
  margin-bottom: 20px;
}
.HomePage .content-details .content-detail-inner a,
.HomePage .service-section .content-detail-inner a {
  font-size: 16px;
  color: #fff;
  text-align: left;
  line-height: 26px;
  font-family: AvertaRegular;
}
.HomePage .content-details .content-detail-inner a:hover,
.HomePage .service-section .content-detail-inner a:hover,
.HomePage .aboutus-section .content-detail-inner a:hover {
  text-decoration: none;
}
.HomePage .categories-box-inner .img-wrapper {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 400px;
  background-position: center center;
}
.HomePage .categories-box-inner .granite1 {
  background-image: url("../../../img/MaskGroup7@3x.png");
}
.HomePage .categories-box-inner .granite2 {
  /* background-image: url("../../../img/Image\ 2@3x.png"); */
}
.HomePage .categories-box-inner .granite3 {
  background-image: url("../../../img/Image\ 3@3x.png");
}
.HomePage .categories-box-inner .granite4 {
  background-image: url("../../../img/Image\ 4@3x.png");
}
.HomePage .underline {
  display: inline;
  position: relative;
  overflow: hidden;
}
.HomePage .underline:after {
  content: "";
  position: absolute;
  z-index: 1;
  right: 0;
  width: 0;
  bottom: -5px;
  background: #fff;
  height: 2px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.HomePage .inner-link {
  cursor: pointer;
}
.HomePage .underline:hover:after,
.HomePage .underline:focus:after,
.HomePage .underline:active:after {
  left: 0;
  right: 50%;
  width: 50%;
}
.HomePage .bottom-btn {
  margin-top: 130px;
}

.HomePage .service-section {
  position: relative;
  height: 100%;
  min-height: 1000px;
  padding: 40px 0 100px 0;
  z-index: 1;
}

#service-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  object-position: center / center;
}

.serImg-overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0)
  );
  width: 100%;
  height: 100%;
  z-index: -1;
}

.HomePage .service-section p,
.HomePage .service-section h3 {
  color: #ffffff;
}

.HomePage .upgrade-content h3 {
  font-family: AvertaSemiBold;
  font-size: 24px;
  line-height: 50px;
  text-transform: none;
  margin: 0;
}

.HomePage .upgrade-content p {
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 20px;
  font-family: AvertaLight;
  font-weight: 300;
}
.HomePage .service-section p.section-head {
  font-size: 20px;
  line-height: 30px;
  font-family: AvertaLight;
}
.HomePage .service-section .line:after {
  width: 50px;
  border: 0.4px solid #d0d0d0;
  height: 0.5px;
  content: "";
  top: 15px;
  left: 15%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%) rotate(360deg);
  margin: 0 auto;
}

.HomePage .ml-60 {
  margin-left: 60px;
}
.HomePage .service-section .btn {
  padding: 10px 20px;
}
.HomePage .service-section .btn-primary {
  background: transparent;
  border: 1px solid #ffffff;
  text-transform: uppercase;
}
.HomePage .service-section .btn-primary:hover {
  background: #ffffff;
  color: #000000;
  border: 1px solid #ffffff;
}
.HomePage .service-section .img-wrapper {
  min-height: 400px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.HomePage .service-section .services-img {
  background-image: url("../../../img/Image5@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.HomePage .service-section .services-img-2 {
  background-image: url("../../../img/kitchen.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.HomePage .service-section .services-img-3 {
  background-image: url("../../../img/bathroom.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.HomePage .body-content p {
  font-size: 14px;
  line-height: 1.86;
  color: #ffffff;
  font-family: AvertaLight;
  font-weight: 300;
}
.HomePage .aboutus-section {
  padding: 0 0 50px;
  position: relative;
}
.HomePage .aboutus-section p.section-head {
  font-size: 20px;
  line-height: 30px;
  font-family: AvertaLight;
}
.HomePage .aboutus-section .line:after {
  width: 40px;
  border: 1px solid #8c9090;
  height: 1px;
  content: "";
  top: 15px;
  left: 12%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%) rotate(360deg);
  margin: 0 auto;
}
.HomePage .aboutus-section .content-p {
  font-size: 14px;
  line-height: 2.4;
  font-family: AvertaRegular;
}
.HomePage .aboutus-section .img-wrapper {
  min-height: 600px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.HomePage .aboutus-section .content-detail-inner a {
  font-size: 14px;
  color: #000000;
  text-align: left;
  line-height: 40px;
  font-family: AvertaRegular;
}
.HomePage .aboutus-section .underline:after {
  content: "";
  position: absolute;
  z-index: 1;
  right: 0;
  width: 0;
  bottom: -5px;
  background: #000000;
  height: 2px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.HomePage .aboutus-section .underline:hover:after,
.HomePage .aboutus-section .underline:focus:after,
.HomePage .aboutus-section .underline:active:after {
  left: 0;
  right: 50%;
  width: 50%;
}
.HomePage .aboutus-section .aboutus-img {
  background-image: url("../../../img/aboutus.png");
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.35s ease-in-out all;
}

.HomePage .aboutus-section .aboutus-img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.HomePage .testimonial-section {
  background-color: #fefefe;
  padding: 80px 0 110px 0;
}
.HomePage .testimonial-section .head-content {
  padding: 0 0 40px 0;
}
.HomePage .testimonial-section h3,
.HomePage .h3 {
  font-size: 42px;
  line-height: 52px;
}
.HomePage .h5 {
  font-size: 28px;
  line-height: 38px;
  color: #a2a3a2;
  font-weight: 500;
  text-transform: lowercase;
  font-family: AvertaThin;
}
.HomePage .categories-section .swiper-container-pointer-events {
  height: 55vh;
}
.HomePage .testimonial-section h5 {
  font-size: 24px;
  line-height: 1.7;
  margin: 0;
  color: #a2a3a2;
  font-weight: 500;
  text-transform: lowercase;
  font-family: AvertaThin;
}
.HomePage .testimonial-section .swiper-container {
  margin: 0;
  width: 100%;
  padding: 20px 0;
}

.quote-open {
  position: absolute;
  top: 30%;
  left: 20%;
}

.quote-close {
  position: absolute;
  top: 80%;
  right: 20%;
}

.HomePage .testi-box {
  width: 360px;
  height: 100%;
  max-height: 350px;
  min-width: 360px;
  margin: 0;
  padding: 20px;
}

.HomePage .testi-box .testi-content p {
  font-family: AvertaLightItalic;
}
.HomePage .testimonial-section .img-wrapper {
  max-width: 100px;
  max-height: 100px;
  margin: -70px auto 30px auto;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.HomePage .testimonial-section .swiper-container .swiper-wrapper .testi-box {
  position: relative;
  z-index: 1;
}

.HomePage
  .testimonial-section
  .swiper-container
  .swiper-wrapper
  .swiper-slide-next
  .swiper-slide
  .testi-box {
  transform: scale(1.3);
  position: relative;
  z-index: 2;
  background: #fff;
  transition: 0.25s ease-in-out all;
  margin: 0;
}

.HomePage
  .testimonial-section
  .swiper-container
  .swiper-wrapper
  .swiper-slide-next
  .swiper-slide
  .testi-box
  p {
  color: #000;
}

.HomePage
  .testimonial-section
  .swiper-container
  .swiper-wrapper
  .swiper-slide-next
  .swiper-slide
  .testi-box
  .img-wrapper {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.HomePage .categories-section .swiper-button-prev:after,
.HomePage .categories-section .swiper-container-rtl .swiper-button-next:after {
  content: "prev";
  font-size: 20px;
  color: #000000;
}
.HomePage .categories-section .swiper-button-next:after,
.HomePage .categories-section .swiper-container-rtl .swiper-button-prev:after {
  content: "next";
  font-size: 20px;
  color: #000000;
}
.HomePage .categories-section .swiper-button-prev,
.HomePage .categories-section .swiper-button-next {
  padding: 5px 25px 5px;
  background: #ffffff;
  opacity: 0.8;
}
.HomePage
  .testimonial-section
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
  position: absolute;
  left: 0;
  right: 0;
  padding: 50px 0;
  bottom: -55px;
}
.HomePage section.service-section .nav-tabs {
  border: none;
  margin-right: 20px;
}
.HomePage section.service-section .nav-tabs .nav-item {
  margin: 0 10px 0 0;
  border-radius: 5px;
}
.HomePage li.light-bdr.nav-item {
  background: rgba(255, 255, 255, 0.1);
  border: 0.2px solid #ffffff;
}
.HomePage .service-section .nav-tabs .nav-link {
  margin-bottom: 0;
  font-size: 12px;
}
.HomePage .nav-tabs .nav-item.show .nav-link,
.HomePage .nav-tabs .nav-link.active,
.HomePage .nav-tabs .nav-link:focus,
.HomePage .nav-tabs .nav-link:hover {
  border-color: #ffffff;
  border-radius: 0;
}
.HomePage .service-section .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border: 1px solid #ffffff;
  border-radius: 0;
}
.HomePage li.light-bdr a {
  color: #ffffff;
}
.HomePage .testi-box .testi-content p {
  margin-bottom: 15px;
  line-height: 1.65;
  font-size: 14px;
  color: #a2a3a2;
}
.HomePage .ft-semibolditalic {
  font-family: AvertaSemiBoldItalic !important;
}
.HomePage span.swiper-pagination-bullet {
  width: 25px;
  height: 5px;
  border-radius: 0;
  color: #a2a3a2;
  background: #a2a3a2;
}
.HomePage .swiper-pagination-bullet-active {
  background-color: #000000 !important;
}
.HomePage li.light-bdr a {
  font-family: AvertaRegular;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 30px;
}
.HomePage .content-detail-inner a {
  font-family: AvertRegular;
}

.HomePage .ss-wrap {
  margin: 30px 0 0 0;
  width: 40%;
}

.HomePage .head-content h3 {
  font-family: AvertaSemiBold;
  font-size: 20px;
  line-height: 50px;
  margin: 0;
}
.HomePage .testimonial-section .head-content h3 {
  font-family: AvertaSemiBold;
  font-size: 40px;
  line-height: 60px;
}

/* Categories Section */

#cat-sec {
  padding: 0 0 50px 0;
}

.cat-item {
  position: relative;
  width: 350px;
  height: 300px;
  max-width: 350px;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 0 35px 0;
}

.cat-item img {
  width: 100%;
  height: 100%;
  z-index: 1;
}

#sec-head {
  font-family: AvertaLight;
  font-size: 20px;
  margin: 0;
}

#cat-sec {
  margin: 0;
}

.cat-line {
  width: 50px;
  height: 1px;
  background: rgba(0, 0, 0, 0.4);
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
}

.cat-item-content {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5) 47%,
    rgba(0, 0, 0, 0.8)
  );
  padding: 30px 20px 20px 20px;
}

.cat-item-content h5,
.cat-item-content p {
  font-family: AvertaRegular;
  font-weight: normal;
  margin: 0;
  color: #ffffff;
}

.cat-item-content p {
  font-family: AvertaLight;
}

.cat-item-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  color: #ffffff;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20%);
  transition: all 0.2s ease-in-out;
}

.cat-item:hover .cat-item-info {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.cat-item:hover h5,
.cat-item:hover p {
  opacity: 0;
}

.cat-item .c-info-head {
  color: #ffffff;
  position: relative;
  padding: 0 0 10px 0;
  margin: 0 0 20px 0;
  font-size: 20px;
}

.cat-item .c-info-head::before {
  content: " ";
  width: 40px;
  height: 1px;
  background: #ffffff;
  position: absolute;
  top: 100%;
  left: 0;
}

.c-info-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.c-info-list li {
  font-size: 14px;
  padding: 0 0 20px 0;
  cursor: pointer;
}

.c-info-list li a {
  text-decoration: none;
  color: #ffffff;
}

.c-info-list li i {
  font-size: 16px;
  padding-left: 4px;
}

.cat-item .more-info {
  font-family: AvertaRegular !important;
  font-weight: 500;
  text-align: center;
  margin: 30% 0 0 0;
}

.cat-item .more-info::before {
  content: " ";
  width: 40px;
  height: 1px;
  background: #ffffff;
  position: absolute;
  top: 100%;
  left: 40%;
}

#catExplore {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  font-family: AvertaLight;
  font-size: 10px;
  font-weight: 300;
  border: none;
  background: none;
  opacity: 0.5;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.cat-divider {
  width: 80%;
  height: 2px;
  background: #fafafa;
  opacity: 0.2;
  position: absolute;
  top: 82%;
  left: 50%;
  transform: translateX(-50%);
}

/* Highlights Section Styles */

#highlights {
  background: #fafafa;
  padding: 120px 0;
}

.HomePage .info-content-box h6 {
  width: 30%;
  text-align: left;
}

.HomePage .info-content-box img {
  max-width: 30px;
  max-height: 30px;
}

.HomePage .info-content p {
  width: 70%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  font-family: AvertaRegular;
}

/* Work Section */

#work-section {
  padding: 40px 0 80px 0;
}

#work-section img {
  display: block;
  margin: 20px auto;
}

#work-section .ws-text {
  font-size: 14px;
  text-align: center;
  line-height: 1.71;
  width: 55%;
  margin: 30px auto 0;
}

#srqQuote {
  border-radius: 5px;
  border: solid 0.5px #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 14px;
  padding: 6px 15px;
  font-weight: 500;
}

#srqQuote:hover {
  background-color: #fff;
  color: #000;
}

@media (min-width: 576px) {
  .hero-section {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
@media (min-width: 768px) {
  .HomePage .aboutus-section .content-box {
    position: absolute;
    top: 15%;
    left: 8%;
  }
}
@media (min-width: 991px) and (max-width: 1024px) {
  .HomePage .testimonial-section .line:after,
  .HomePage .categories-section .line:after {
    left: 15% !important;
  }
}
@media (min-width: 991px) {
  .HomePage .hero-section {
    width: 100%;
    height: 100vh;
    position: relative;
  }

  .HomePage .hero-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.5;
  }

  .HomePage .aboutus-section:before {
    content: "";
    width: 42%;
    height: 100%;
    background: #fafafa;
    position: absolute;
  }
  .HomePage .vertical-line {
    position: absolute;
    width: 1px;
    border: 1px solid #eeeeee;
    height: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -140px;
  }
  .HomePage .vertical-line-2 {
    position: absolute;
    width: 1px;
    border: 1px solid #eeeeee;
    height: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -155px;
  }

  .HomePage .testimonial-section .swiper-slide.swiper-slide-next .testi-box {
    margin: 0;
  }
  .HomePage .testimonial-section .testi-box {
    margin: 0;
  }
  .HomePage .testimonial-section .swiper-slide {
    margin: 0;
  }
  .HomePage .testimonial-section .swiper-slide.swiper-slide-next {
    margin: 0;
    z-index: 2;
    position: relative;
  }

  .HomePage .testimonial-section .swiper-slide.swiper-slide-next .testi-box {
    margin: 0;
    z-index: 2;
    position: relative;
    padding: 20px 30px;
  }

  .HomePage .testimonial-section .swiper-slide .testi-box {
    padding: 20px 30px;
  }
  .HomePage .testimonial-section .swiper-slide.swiper-slide-next .img-wrapper {
    max-width: 100px;
    max-height: 100px;
    margin: -70px auto 30px auto;
  }

  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
    padding: 50px 0;
    margin: 50px 0;
  }
  .HomePage .testimonial-section .swiper-slide-next .testi-box {
    width: 100%;
    padding: 20px;
  }
}
@media (min-width: 767px) and (max-width: 991px) {
  .HomePage .testimonial-section .content-box {
    margin-top: 25px;
  }
}
@media (max-width: 991px) {
  a.navbar-brand.logo-size.logo-small {
    display: none;
  }
  .HomePage .aboutus-section .content-p {
    font-size: 14px;
    line-height: 20px;
  }
  .HomePage .testimonial-section h5 {
    font-size: 30px;
  }
  .HomePage .testimonial-section .head-content h3 {
    font-family: AvertaSemiBold;
    font-size: 35px;
    line-height: 0px;
  }
  .HomePage .testimonial-section .head-content {
    position: absolute;
    left: 10px;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }
  .HomePage section {
    padding: 0;
  }
  .HomePage .categories-box-inner {
    margin-top: 60px;
  }
  .HomePage .categories-section .content-box {
    position: absolute;
    left: 15%;
    top: 0;
  }
  .HomePage .aboutus-section .line:after {
    top: 15px;
    left: 15%;
    width: 40px;
  }
  .HomePage .categories-section .line:after,
  .HomePage .testimonial-section .line:after {
    width: 30px;
    top: 15px;
    left: 15%;
  }
  .HomePage .testi-content {
    padding: 0;
    margin-top: 0;
  }
  .HomePage .aboutus-section {
    padding: 40px 0 25px;
  }
  .HomePage .bottom-btn {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .HomePage .testimonial-section .head-content {
    position: relative;
    margin: 0 auto;
    text-align: center;
  }

  .quote-open {
    position: absolute;
    top: 27%;
    left: 10%;
  }

  .quote-close {
    position: absolute;
    top: 90%;
    right: 10%;
  }

  .HomePage
    .testimonial-section
    .swiper-container
    .swiper-wrapper
    .swiper-slide-next
    .swiper-slide
    .testi-box {
    transform: scale(0);
  }

  .HomePage .testimonial-section .img-wrapper {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
  }

  .HomePage .testi-box {
    width: 90%;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .HomePage .btn {
    padding: 7px 20px;
  }
  .HomePage .categories-section .content-box {
    transform: none;
  }
  .testimonial-section .ml-60,
  .HomePage .categories-section .ml-60 {
    margin: 0 !important;
  }
  .HomePage .testimonial-section p.section-head,
  .HomePage .categories-section p.section-head {
    margin-right: 30px;
  }
  .HomePage .testimonial-section .line:after,
  .HomePage .categories-section .line:after {
    width: 20px;
    top: 15px;
    left: 18%;
  }
  .HomePage .service-section .line:after {
    width: 40px;
    top: 15px;
    left: 23%;
  }
  .HomePage .aboutus-section .line:after {
    top: 15px;
    left: 16%;
    width: 35px;
  }
  .HomePage .hero-content {
    position: relative;
    top: 100px;
    left: 0;
    width: 100%;
    height: fit-content;
    padding: 20px 0 20px 40px;
  }

  .HomePage .hero-content img {
    width: 350px;
    margin: 0 0 10px 0;
  }

  .HomePage .ft-regular {
    font-size: 20px;
    margin: 0;
    line-height: 0;
  }

  .hero-section {
    margin: 0;
    background-attachment: fixed;
    position: relative;
    height: 100vh;
  }

  .HomePage .head-dropdown {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    margin: 0 auto;
  }

  #productSearchHeader {
    font-size: 16px;
    margin: 0 0 40px 0;
  }

  .HomePage .head-dropdown p {
    font-size: 16px;
    margin: 0 0 20px 0;
  }

  .HomePage .dropdown {
    padding: 0 0 40px 0;
  }

  .cat-item {
    width: 100%;
    max-width: 100%;
  }

  #cat-sec {
    margin: 20px auto 0 auto !important;
  }

  .HomePage .info-content-box h6 {
    width: 50%;
  }

  .HomePage .info-content p {
    width: 90%;
  }

  .HomePage .service-section {
    min-height: 700px;
  }

  .HomePage .ss-wrap {
    width: 70%;
  }

  .HomePage .head-content h3 {
    line-height: 1.5;
    margin-bottom: 20px;
  }
  /* Work Section */

  #highlights {
    padding: 70px 0;
  }

  #work-section {
    padding: 40px 0 50px 0;
  }

  #work-section .ws-text {
    width: 90%;
  }

  .HomePage .aboutus-section .content-box {
    position: absolute;
    left: 30px;
    width: 100%;
  }

  .HomePage .aboutus-section .content-p {
    padding-right: 0 !important;
    line-height: 1.8;
  }

  .HomePage
    .testimonial-section
    .swiper-container
    .swiper-wrapper
    .swiper-slide-next
    .swiper-slide
    .testi-box {
    transform: scale(1);
  }
}

@media (max-width: 575px) {
  .HomePage .hero-content {
    position: absolute;
    top: 90px;
    left: 0;
    background: rgb(0, 0, 0, 0.5);
    width: 100%;
    height: fit-content;
    padding: 20px;
  }

  .HomePage .hero-content img {
    width: 200px;
  }

  .HomePage .ft-regular {
    font-size: 14px;
    margin: 0;
    line-height: 0;
  }

  .hero-section {
    margin: 0;
    position: relative;
    height: 100vh;
  }

  .HomePage .head-dropdown {
    position: absolute;
    top: 67%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    margin: 0 auto;
  }

  #productSearchHeader {
    font-size: 12px;
    margin: 0 0 10px 0;
  }

  .HomePage .head-dropdown p {
    font-size: 14px;
  }

  .HomePage .dropdown {
    padding: 0 0 25px 0;
  }

  /* Categories Section */

  #cat-sec {
    margin: 20px 0 0 0 !important;
  }

  .cat-item {
    width: 100%;
    max-width: 100%;
  }

  /* Highlights Section */

  #highlights {
    padding: 50px 0;
  }

  .HomePage .info-content p {
    font-size: 12px;
    width: 100%;
    padding: 0 20px;
    text-align: left;
  }

  .HomePage .ss-wrap {
    width: 100%;
    margin: 20px 0 0 0;
    background: rgb(0, 0, 0, 0.6);
    border-radius: 6px;
    padding: 15px;
  }

  .HomePage .head-content h3 {
    line-height: 1.5;
    margin: 0 0 20px 0;
  }

  .HomePage section.service-section .nav-tabs {
    margin: 0;
  }

  .HomePage section.service-section .nav-tabs .nav-item {
    margin: 0;
  }

  /* Services Section */

  .HomePage .service-section {
    padding-top: 20px;
  }

  /* Work Section */
  #work-section .ws-text {
    width: 100%;
    margin: 0 0 30px 0;
    padding: 0 10px;
  }

  /* About Section */

  .HomePage .aboutus-section {
    background: #fafafa;
    margin: 0;
  }

  .HomePage .aboutus-section .content-p {
    line-height: 2;
    margin: 0;
  }

  .HomePage .aboutus-section .aboutus-img {
    width: 100%;
    height: 100%;
  }

  .HomePage .aboutus-section .img-wrapper {
    width: 100%;
  }

  .HomePage .aboutus-section .aboutus-img {
    background-size: cover;
    width: 98%;
  }

  @media (max-width: 330px) {
    .HomePage .hero-content img {
      width: 150px;
    }

    #productSearchHeader {
      font-size: 10px;
      margin: 0 0 10px 0;
    }

    .HomePage .head-dropdown {
      top: 67%;
      width: 95%;
      padding: 15px 10px !important;
    }

    .HomePage .head-dropdown p {
      font-size: 12px;
    }

    .HomePage .dropdown {
      padding: 0 0 10px 0;
    }

    .HomePage
      .testimonial-section
      .swiper-container
      .swiper-wrapper
      .swiper-slide-next
      .swiper-slide
      .testi-box {
      transform: scale(0);
    }

    .HomePage .testimonial-section .img-wrapper {
      filter: grayscale(0);
      -webkit-filter: grayscale(0);
    }

    .HomePage .testi-box {
      width: 90%;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 1700px) {
  .indicators-custom {
    bottom: 35px;
  }

  .HomePage .head-dropdown {
    padding: 10px 20px;
  }

  #productSearchHeader {
    margin: 0 0 15px 0;
  }

  .HomePage .dropdown {
    padding: 0 0 25px 0;
  }
}
