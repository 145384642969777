@font-face {
  font-family: Averta;
  src: local(Averta), url(../../../fonts/Averta/Averta.otf);
}
@font-face {
  font-family: AvertaThin;
  src: local(AvertaThin), url(../../../fonts/Averta/Averta\ Thin.otf);
}
@font-face {
  font-family: AvertaRegular;
  src: local(AvertaRegular), url(../../../fonts/Averta/AvertaDemo-Regular.otf);
}
@font-face {
  font-family: AvertaBold;
  src: local(AvertaBold), url(../../../fonts/Averta/Averta\ Bold.otf);
}
@font-face {
  font-family: AvertaLight;
  src: local(AvertaLight), url(../../../fonts/Averta/Averta\ Light.otf);
}
a,
p {
  font-family: AvertaRegular;
}

/* Global select styles */

select option:placeholder-shown {
  font-size: 14px;
}

.navbar {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: transparent;
  transition: 0.2s ease-in-out all;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

#navbarCollapse {
  justify-content: flex-end;
}

.header-page .navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.header-page .navbar-container .navbar-toggle-icon {
  color: #fff;
}

.header-page .navbar-container .navbar-toggler {
  border: 1px solid #fff;
  padding: 7px 10px;
}

.bgNav-white {
  background: #ffffff !important;
  color: #000000 !important;
}

.bgNav-text {
  color: #000000 !important;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}
.bg-white {
  background-color: #fff !important;
}
.header-page .navbar-container .nav-item .nav-link {
  color: #fff;
  text-decoration: none;
}

.header-page .navbar-container .nav-item .nav-link:hover {
  color: #fff;
}

.header-page .navbar-container {
  color: #fff;
  text-decoration: none;
}

.profile-dd:hover {
  color: #fff;
}

.nav-item {
  margin: 15px;
  font-size: 16px;
  color: #fff;
}

.aboutus-link:hover,
.services-link:hover,
.contactus-link:hover {
  color: #fff;
}

.dropdown-item {
  font-size: 14px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #000000 !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:active,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:focus-within {
  color: rgba(0, 0, 0, 0.7);
}

nav.navbar.navbar-expand-lg.navbar-light.bg-white {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
}
.logo-size img {
  max-width: 60px;
  max-height: 60px;
}
.quote .nav-link,
.signin .nav-link {
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid #ffffff;
  margin: 20px;
  padding: 5px;
  transition: 0.25s all ease;
  color: #fff;
}

#quoteLink,
#signInLink {
  color: #fff;
  font-size: 14px;
  margin-right: 0;
}

#quoteLink:hover,
#signInLink:hover {
  border: 0.5px solid #ffffff;
  background: #ffffff;
  color: #000000;
}

#quoteLinkWhite,
#signInLinkWhite {
  border-radius: 5px;
  border: solid 0.5px #000000;
  background-color: rgba(255, 255, 255, 0.05);
  color: #000;
  transition: 0.25s ease-in-out all;
  margin-right: 0;
}

#quoteLinkWhite:hover,
#signInLinkWhite:hover {
  background-color: #000;
  color: #fff;
}

#quoteLinkAsWhite {
  border-radius: 5px;
  border: solid 0.5px #000000;
  background-color: rgba(255, 255, 255, 0.05);
  color: #000;
}

#quoteLinkAsWhite:hover {
  background-color: #000;
  color: #fff;
}

#quoteLinkHome {
  font-size: 14px;
}

#quoteLinkHome:hover {
  background-color: #fff;
  color: #000;
}

#navbarDropdownWhite {
  color: rgba(0, 0, 0, 0.5);
}

#navbarDropdownWhite:active,
#navbarDropdownWhite:focus,
#navbarDropdownWhite:focus-within,
#navbarDropdownWhite:hover {
  color: #000;
}

#navbarDropdownAsWhite {
  color: rgba(0, 0, 0, 0.5);
}

#navbarDropdownAsWhite:active,
#navbarDropdownAsWhite:focus,
#navbarDropdownAsWhite:focus-within,
#navbarDropdownAsWhite:hover {
  color: #000;
}

.navbar-whitebg .navbar-nav .nav-item .nav-link::before {
  content: "";
  position: absolute;
  z-index: 1;
  right: 0px;
  left: 8px !important;
  width: 0;
  bottom: 5px;
  background: #000;
  height: 2px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.navbar-whitebg .navbar-nav .underline-white:hover:after,
.navbar-whitebg .navbar-nav .underline-white:focus:after,
.navbar-whitebg .navbar-nav .underline-white:active:after {
  right: 50%;
  width: 50%;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
  position: relative;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 9px;
  width: 40px;
  height: 2px;
  background: #000;
}

.buttonHoverNav {
  background: transparent !important;
  color: #000000 !important;
  border: none;
  border: 0.5px solid #000000 !important;
}

.buttonHoverNav:hover {
  background: #000000 !important;
  color: #ffffff !important;
}

button {
  border: 2px solid #000000;
  transition: 0.5s ease-in-out;
  border-radius: 5px !important;
}

input {
  font-size: 14px !important;
}

input::placeholder {
  font-size: 14px !important;
}

footer {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../img/footer_img.png");
  background-size: cover;
  background-position: center;
  padding: 40px 0 0 0;
}
.ft-logo img {
  max-width: 150px;
  max-height: 100px;
}
footer p,
footer a {
  font-size: 16px !important;
  line-height: 20px !important;
  color: #ffffff;
  font-family: AvertaLight;
  margin-bottom: 20px !important;
}
.ft-about,
.ft-contact,
.ft-products,
.ft-services,
.ft-tandc,
.ft-pp,
.ft-fb,
.ft-twt,
.ft-insta,
.ft-linkedin {
  margin-bottom: 15px;
}
footer a:hover {
  color: #ffffff !important;
  text-decoration: none !important;
}
footer hr.footer-hr {
  border-top: 1px solid #323232;
}
footer h6,
footer .h6 {
  font-size: 14px !important;
  line-height: 20px !important;
  margin-bottom: 10px !important;
  text-align: left !important;
  font-family: AvertaBold;
  color: #a2a3a2 !important;
  text-transform: uppercase;
}
footer .cpy-ryt {
  font-size: 12px !important;
  line-height: 20px !important;
  letter-spacing: 2.4px;
}
footer .text-center p {
  text-align: center !important;
}
.signin-logo img {
  max-width: 150px;
  max-height: 150px;
  margin: 10px;
}
@media (min-width: 567px) {
}
@media (max-width: 767px) {
  .header-page .navbar-container {
    justify-content: space-between;
    margin: 0 auto;
    width: 95%;
  }

  #navbarCollapse {
    background: #fff;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-bottom: 30px;
  }

  .navbar-nav {
    padding-top: 70px;
    height: 100%;
  }

  .header-page .navbar-container .nav-item .nav-link {
    color: #000;
  }

  .header-page .navbar-container .nav-item .nav-link:hover {
    color: #000;
  }

  .login-btn {
    padding: 0;
  }

  .nav-item {
    color: #000;
  }

  .white-toggle {
    border: 1px solid #fff !important;
  }

  .white-toggle-icon {
    color: #fff !important;
  }

  #quoteLink,
  #signInLink {
    color: #000;
    border: 1px solid #000;
    width: 95%;
    margin: 10px auto;
  }

  #quoteLinkWhite,
  #signInLinkWhite {
    color: #000;
    border: 1px solid #000;
    width: 95%;
    margin: 10px auto;
  }

  .header-page .navbar-container .navbar-toggler {
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 10px;
    border: 1px solid #000;
  }

  .header-page .navbar-container .navbar-toggle-icon {
    color: #000;
  }

  .logo-size img {
    position: relative;
    z-index: 2;
  }

  footer {
    text-align: center !important;
  }
  footer h6,
  footer .h6 {
    text-align: center !important;
  }
  .ft-cell,
  .ft-email,
  .ft-add {
    justify-content: center !important;
  }
  footer .ft-day p {
    text-align: center !important;
  }
}
.nav-link {
  cursor: pointer;
}
.view-hover {
  cursor: pointer;
}
.head-bg {
  background-image: url("../../../img/close-up-black-marble-textured-background@3x.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 992px) {
  .logo-big img {
    max-width: 150px;
    max-height: 150px;
  }
  #js-header .logo-big {
    width: 200px;
    height: 200px;
  }

  #js-header .logo-small .img-fluid {
    width: 80px;
    height: 80px;
  }

  #js-header.smaller-logo .logo-small {
    display: block;
  }

  #js-header.smaller-logo .logo-big {
    display: none;
  }
  #js-header .logo-big {
    display: block;
    min-width: 100%;
    text-align: center;
  }

  #js-header .logo-small {
    display: none;
  }
  nav#js-header {
    width: 100%;
    /* overflow: hidden; */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: all 0.5s ease-in-out;
  }
  #js-header .big-logo-position {
    position: absolute;
    text-align: center;
    min-width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
  }
  nav.smaller-logo .big-logo-position {
    position: relative !important;
  }
}
@media (max-width: 567px) {
  .otp {
    width: 15% !important;
  }
  .modal {
    top: 0 !important;
  }
  .signin .nav-link,
  .quote .nav-link {
    padding: 10px 20px;
    text-align: center;
  }
}
.Snackbar_snackbar-wrapper__ocbPJ {
  z-index: 2000;
}
.header-page .underline {
  display: inline;
  position: relative;
}
.header-page .underline:after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0px;
  left: 8px !important;
  width: 0;
  bottom: 5px;
  background: #fff;
  height: 2px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.header-page .underline-whitebg:after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0px;
  left: 8px !important;
  width: 0;
  bottom: 5px;
  background: #000;
  height: 2px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.header-page .underline:hover:after,
.header-page .underline:focus:after,
.header-page .underline:active:after {
  left: 0;
  right: 50%;
  width: 50%;
}
.quote .nav-link,
.signin .nav-link {
  border-radius: 5px;
}

@media (max-width: 800px) {
  .header-page .navbar-container {
    color: #000;
  }
  .header-page .navbar-container .nav-item .nav-link {
    color: #000;
  }

  .quote .nav-link,
  .signin .nav-link {
    padding: 10px;
    text-align: center;
  }
}
