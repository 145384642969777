section.gallery-section {
  padding: 110px 0 50px;
}

section.gallery-section h3 {
  font-family: "AvertaBold";
  font-size: 50px;
  color: #fff;
  margin: 0;
}

section.gallery-section h5 {
  font-family: "AvertaRegular";
  font-size: 16px;
  margin: 12px 0 0;
  color: #fff;
}

section.gallery-section p {
  width: 50%;
  font-family: "AvertaRegular";
  font-size: 14px;
  margin: 12px 0 0;
  color: #fff;
  line-height: 1.8;
}

section.gallery-section #back {
  font-family: AvertaSemiBold;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
}

section.gallery-section .gallery-header {
  width: 100%;
  height: 400px;
  padding: 0 80px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.85) 40%,
      rgba(0, 0, 0, 0.25)
    ),
    url("../../../img/galleryHeader.jpg") no-repeat center center;
  background-size: cover;
}

section.gallery-section .gallery-area {
  margin: 100px 0;
}

section.gallery-section .gallery-area.view {
  margin: 50px 0;
}

section.gallery-section .gallery-area img {
  width: 100%;
  height: 100%;
}

section.gallery-section .gallery-area p {
  font-size: 15px;
  text-align: left;
  line-height: 1.85;
  color: #555;
  width: 100%;
}

section.gallery-section #enquireNowBtn {
  font-family: "AvertaBold";
  padding: 5px 20px;
  border: 2px solid #000;
  margin: 20px 0 0;
  border-radius: 0 !important;
  background: none;
  transition: all 0.15s ease-in-out;
}

section.gallery-section #enquireNowBtn:hover {
  background: #000;
  color: #fff;
}

span[data-cursor] {
  cursor: pointer;
}

section.gallery-section .showcase-area .showcase-main {
  width: 100%;
  height: 450px;
  min-height: 450px;
  max-height: 450px;
  object-fit: cover;
  object-position: center;
}

section.gallery-section .showcase-area .showcase-thumb {
  display: flex;
  gap: 12.5px;
  flex-wrap: wrap;
  margin: 30px 0;
}

section.gallery-section .showcase-area .showcase-thumb img.thumb-img {
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
  min-width: 80px;
  min-height: 80px;
  object-fit: cover;
  object-position: center;
  border: 2px solid transparent;
}

section.gallery-section .showcase-area .showcase-thumb img.thumb-img.active {
  border: 2px solid #111;
}

section.gallery-section .gallery-area .image-item {
  width: 100%;
  height: 220px;
  min-height: 220px;
  position: relative;
  margin-bottom: 25px;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out background;
  border: none;
  border-radius: 10px;
  overflow: hidden;
}

section.gallery-section .gallery-area .image-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 20%,
    rgba(0, 0, 0, 0.05)
  );
}

section.gallery-section .gallery-area .image-item h6 {
  font-family: AvertaRegular;
  position: absolute;
  left: 30px;
  bottom: 15px;
  color: #fff;
}

section.gallery-section .gallery-no-img {
  padding: 50px 0 30px;
}

section.gallery-section .gallery-no-img img {
  display: block;
  width: 300px;
  margin: 0 auto;
  opacity: 0.75;
}

section.gallery-section .gallery-no-img h6 {
  margin: 30px 0 0;
  text-align: center;
  color: #999;
  text-transform: none;
}
