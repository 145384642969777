@font-face {
  font-family: Averta;
  src: local(Averta), url(../../../fonts/Averta/Averta.otf);
}
@font-face {
  font-family: AvertaRegular;
  src: local(AvertaRegular), url(../../../fonts/Averta/AvertaDemo-Regular.otf);
}
@font-face {
  font-family: AvertaThin;
  src: local(AvertaThin), url(../../../fonts/Averta/Averta\ Thin.otf);
}
@font-face {
  font-family: AvertaLight;
  src: local(AvertaLight), url(../../../fonts/Averta/Averta\ Light.otf);
}
@font-face {
  font-family: AvertaBold;
  src: local(AvertaBold), url(../../../fonts/Averta/Averta\ Bold.otf);
}
@font-face {
  font-family: AvertaSemiBold;
  src: local(AvertaSemiBold), url(../../../fonts/Averta/Averta\ Semibold.otf);
}
@font-face {
  font-family: AvertaLightItalic;
  src: local(AvertaLightItalic),
    url(../../../fonts/Averta/Averta\ Light\ Italic.otf);
}
@font-face {
  font-family: AvertaSemiBoldItalic;
  src: local(AvertaSemiBoldItalic),
    url(../../../fonts/Averta/Averta\ Semibold\ Italic.otf);
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #000000;
  font-weight: 700;
  text-transform: capitalize;
}

.services h1,
.services .h1 {
  font-size: 70px;
  line-height: 90px;
  margin-bottom: 30px;
}

.services .h2,
.services h2 {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 20px;
}

.services h3,
.services .h3 {
  font-size: 45px;
  line-height: 55px;
  margin-bottom: 20px;
}

.services h4 {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 15px;
}

.services h6 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  color: #000;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p {
  color: #fff;
}

.bg-dark {
  background-color: #000 !important;
}

.services section {
  padding: 80px 0 50px;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.page-wrapper {
  background: #fff;
}
.services .btn {
  font-family: AvertaRegular;
  font-size: 16px;
  padding: 5px 30px;
  border-radius: 0;
}
.services .btn-light {
  background: transparent;
  color: #000;
  border: 1px solid #000000;
}
.services .btn-light:hover {
  background-color: #000000;
  color: #ffffff;
}
.services .btn-dark {
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}
.services .btn-dark:hover {
  background-color: #000000;
  color: #ffffff;
}
.wow {
  visibility: hidden;
}

.note-editable .wow {
  visibility: visible;
}

.services .content-box p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.services .servicepage a {
  color: #cccccc;
}

.services .content-box {
  font-size: 20px;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
}

.services .body-section-1 {
  background-image: url("../../../img/Image 57@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.services .white-box {
  background: #ffffff;
}

.services .white-box p.number {
  font-family: AvertaLight;
  font-size: 14px;
  margin-bottom: 5px;
}
.services .white-box h4 {
  font-size: 30px;
  line-height: 40px;
  font-family: AvertaSemiBold;
}
.services .white-box p.para-content {
  font-family: AvertaRegular;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 30px;
  color: #000000;
}

.services .services .item-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  height: 480px;
  line-height: 0;
}

.swiper-pagination-progressbar {
  top: auto;
  bottom: 10px;
  left: calc(50% - 200px);
  width: 400px;
  height: 5px;
  background: rgba(200, 200, 200, 0.15);
  border-radius: 10px;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #000000;
  border-radius: 10px;
}

.services .swiper-container .swiper-slide:hover .content .item-img:after {
  opacity: 1;
}

.services .swiper-container .swiper-slide:hover .content .cont {
  opacity: 1;
  visibility: visible;
}

.services .swiper-container .content {
  position: relative;
  margin-bottom: 50px;
}

.services .swiper-container .content .overlay-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
}
.services .swiper-container .content .item-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  height: 480px;
  line-height: 0;
}

.services .swiper-container .img1 {
  background-size: cover !important;
  min-width: 400px !important;
  background-repeat: no-repeat;
  max-height: 480px !important;
  object-fit: cover;
}
.services span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #000000;
}
.services .swiper-pagination-bullet {
  width: 25px;
  height: 5px;
  display: inline-block;
  border-radius: 0;
  background: #a3a4a3;
  opacity: 1;
}
.services .swiper-button-prev:after,
.services .swiper-container-rtl .swiper-button-next:after {
  content: "prev";
  font-size: 20px;
  color: #000000;
}
.services .swiper-button-next:after,
.services .swiper-container-rtl .swiper-button-prev:after {
  content: "next";
  font-size: 20px;
  color: #000000;
}

.services .swiper-button-prev,
.services .swiper-button-next {
  padding: 5px 25px 5px;
  background: #ffffff;
  opacity: 0.8;
}
.services .swiper-container .content .item-img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000009e;
  background: #0000009e;
  background: #0000009e;
  background: #0000009e;
  opacity: 0;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.services .swiper-container .content:hover .item-ig {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.services .swiper-container .content .cont {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  top: 0;
  text-align: left !important;
}

.services .swiper-container .swiper-nav-ctrl {
  border-radius: 50%;
  border: 2px solid #ff6d54;
  bottom: 0;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  height: 50px;
  line-height: 46px;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
}

.services .swiper-container h6 {
  font-family: AvertaSemiBold;
  text-align: left;
  color: #ffffff !important;
  font-size: 20px !important;
  line-height: 30px !important;
  margin-bottom: 20px;
}

.services .swiper-container p.menu-number {
  font-family: AvertaLight;
  font-size: 14px;
  margin-bottom: 0;
}
.servicemodal .btn {
  font-family: AvertaRegular;
  font-size: 16px;
  padding: 5px 20px;
}
.services .swiper-container p.menu-content {
  font-family: AvertaLight;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 10px;
}
.servicemodal .modal-content-box p.number {
  font-family: AvertaLight;
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 0;
}
.servicemodal .modal-content-box h3 {
  font-family: AvertaSemiBold;
  font-size: 40px;
  line-height: 50px;
}
.servicemodal .modal-content-box p {
  font-family: AvertaLight;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0;
}
.services .swiper-container .btns {
  position: absolute;
  bottom: 35px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
.services .body-section-2 {
  position: relative;
  background: url("../../../img/Image 59@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.services .body-section-2:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #00000094;
}
.services .body-section-2 .inner-box {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 25px;
}
.body-section-2 .inner-box img {
  max-width: 35px;
  margin-bottom: 20px;
}
.body-section-2 .inner-box p {
  font-family: AvertaRegular;
  font-size: 16px;
  color: #000000;
  margin-bottom: 10px;
}
.bg-grey {
  background-color: #fafafa;
}
.body-section-2 .bg-white {
  padding: 30px;
}
.body-section-2 p.para-content {
  font-family: AvertaRegular;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 30px;
  color: #000000;
}
.bottom-fixed-section p.number {
  font-family: AvertaLight;
  font-size: 14px;
  margin-bottom: 5px;
  color: #ffffff;
}
.services .transparent-bg h4 {
  font-family: AvertaSemiBold;
  font-size: 40px;
  line-height: 50px;
}
.services .homepage a {
  color: #cccccc;
  font-family: AvertaLight;
  font-size: 14px;
  line-height: 17px;
}
.services i.fa.fa-circle {
  font-size: 7px;
  margin: 5px;
}
.modal-inner .inner-box {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 25px;
}
.modal-inner .inner-box img {
  max-width: 35px;
  margin-bottom: 20px;
}
.modal-inner .inner-box p {
  font-family: AvertaRegular;
  font-size: 16px;
  color: #000000;
  margin-bottom: 10px;
}
.modal-img {
  /* background: url("../../../img/Image 59@3x.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 425px;
  min-width: 500px;
}
.services .content-box h2 {
  font-family: AvertaBold;
  font-size: 50px;
  line-height: 60px;
}
.services .content-box p {
  font-family: AvertaRegular;
  font-size: 16px;
  line-height: 30px;
}
.servicemodal .modal-header .close {
  padding: 1rem 1rem;
  margin: 0rem -1rem -1rem auto !important;
}
@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .servicemodal {
    max-width: 1200px !important;
  }
  .body-section-2 .bottom-fixed-section {
    position: absolute;
    bottom: 0;
  }
}
@media (min-width: 992px) {
  .modal-img {
    min-width: 500px !important;
  }
  .services .hero-section-services {
    padding-top: 150px;
  }
}
@media (min-width: 1025px) {
  .modal-img {
    min-height: 425px;
    min-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .servicemodal .modal-content-box h3 {
    font-size: 30px;
    line-height: 35px;
  }
  .servicemodal .modal-content-box p {
    font-size: 14px;
    line-height: 22px;
  }
  .modal-img {
    min-height: 425px;
    min-width: 400px;
  }
}
@media (max-width: 991px) {
  .services .swiper-container p.menu-content {
    font-size: 14px;
    line-height: 23px;
  }
}

@media (max-width: 767px) {
  .modal-img {
    min-width: 0 !important;
  }
  .servicemodal .modal-header .close {
    position: absolute;
  }
  section {
    padding: 40px 0 25px;
  }

  .res-mb {
    margin-bottom: 10px !important;
  }

  h4,
  .h4 {
    font-size: 24px;
    line-height: 34px;
  }

  .services .content-box {
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
  }
  .transparent-bg {
    background-color: #ffffff;
    padding: 20px;
  }
  .body-section-2 .bg-white {
    padding: 20px;
  }
  .transparent-bg p.number {
    color: #000000 !important;
  }
  .transparent-bg h4 {
    color: #000000 !important;
  }
  .white-box p.para-content {
    font-size: 18px;
    line-height: 35px;
    text-align: justify;
    font-weight: 400;
  }
}

@media (max-width: 575px) {
}
