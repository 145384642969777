@font-face {
  font-family: Averta;
  src: local(Averta), url(../../../fonts/Averta/Averta.otf);
}
@font-face {
  font-family: AvertaRegular;
  src: local(AvertaRegular), url(../../../fonts/Averta/AvertaDemo-Regular.otf);
}
@font-face {
  font-family: AvertaThin;
  src: local(AvertaThin), url(../../../fonts/Averta/Averta\ Thin.otf);
}
@font-face {
  font-family: AvertaLight;
  src: local(AvertaLight), url(../../../fonts/Averta/Averta\ Light.otf);
}
@font-face {
  font-family: AvertaBold;
  src: local(AvertaBold), url(../../../fonts/Averta/Averta\ Bold.otf);
}
@font-face {
  font-family: AvertaSemiBold;
  src: local(AvertaSemiBold), url(../../../fonts/Averta/Averta\ Semibold.otf);
}
@font-face {
  font-family: AvertaLightItalic;
  src: local(AvertaLightItalic),
    url(../../../fonts/Averta/Averta\ Light\ Italic.otf);
}
@font-face {
  font-family: AvertaSemiBoldItalic;
  src: local(AvertaSemiBoldItalic),
    url(../../../fonts/Averta/Averta\ Semibold\ Italic.otf);
}

.tc-hero-section {
  padding-top: 150px;
}

.pp-hero-section {
  padding-top: 150px;
}

.termspage p {
  font-family: AvertaLight;
  font-size: 16px;
  line-height: 30px;
}
.termspage .homepage a {
  color: #cccccc;
  font-family: AvertaLight;
  font-size: 14px;
  line-height: 17px;
}
.termspage i.fa.fa-circle {
  font-size: 7px;
  margin: 5px;
}
.termspage .content-box h2 {
  font-family: AvertaBold;
  font-size: 50px;
  line-height: 60px;
  margin-bottom: 30px;
}
.termspage h5 {
  font-family: AvertaSemiBold;
  font-size: 24px;
  line-height: 34px;
}
@media (min-width: 992px) {
  .termspage .hero-section {
    padding-top: 150px;
  }
}
