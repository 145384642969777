@font-face {
  font-family: Averta;
  src: local(Averta), url(../../../fonts/Averta/Averta.otf);
}
@font-face {
  font-family: AvertaRegular;
  src: local(AvertaRegular), url(../../../fonts/Averta/AvertaDemo-Regular.otf);
}
@font-face {
  font-family: AvertaThin;
  src: local(AvertaThin), url(../../../fonts/Averta/Averta\ Thin.otf);
}
@font-face {
  font-family: AvertaLight;
  src: local(AvertaLight), url(../../../fonts/Averta/Averta\ Light.otf);
}
@font-face {
  font-family: AvertaBold;
  src: local(AvertaBold), url(../../../fonts/Averta/Averta\ Bold.otf);
}
@font-face {
  font-family: AvertaSemiBold;
  src: local(AvertaSemiBold), url(../../../fonts/Averta/Averta\ Semibold.otf);
}
@font-face {
  font-family: AvertaLightItalic;
  src: local(AvertaLightItalic),
    url(../../../fonts/Averta/Averta\ Light\ Italic.otf);
}
@font-face {
  font-family: AvertaSemiBoldItalic;
  src: local(AvertaSemiBoldItalic),
    url(../../../fonts/Averta/Averta\ Semibold\ Italic.otf);
}
.quotespage p {
  margin-bottom: 20px;
}
.quotespage h1,
.quotespage h2,
.quotespage h3,
.quotespage h4,
.quotespage h5,
.quotespage h6 {
  color: #000000;
  text-transform: capitalize;
}
.quotespage h1,
.quotespage .h1 {
  font-size: 70px;
  line-height: 90px;
  margin-bottom: 30px;
}
.quotespage h3,
.quotespage .h3 {
  font-size: 45px;
  line-height: 55px;
  margin-bottom: 20px;
}
.quotespage h4,
.quotespage .h4 {
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 15px;
}
.quotespage h5,
.quotespage .h5 {
  font-family: AvertaRegular;
  font-size: 16px;
  line-height: 1.25;
  color: #000000;
}
.quotespage h6,
.quotespage .h6 {
  font-family: AvertaLight;
  font-size: 14px;
  color: #a2a3a2;
  font-weight: 400;
  display: contents;
}
.quotespage h6 span {
  font-family: AvertaSemiBold;
  font-size: 20px;
  margin-left: 15px;
  line-height: 30px;
  color: #000000;
  font-weight: 600;
}
.quotespage .btn-link:hover,
.quotespage .btn-link.focus,
.quotespage .btn-link:focus {
  color: #ffffff;
  text-decoration: none;
}
.quotespage p > a {
  color: inherit;
}
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p {
  color: #fff;
}
.bg-dark {
  background-color: #000 !important;
}
.quotespage section {
  padding: 80px 0 0px;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.page-wrapper {
  background: #fff;
}
.wow {
  visibility: hidden;
}
.note-editable .wow {
  visibility: visible;
}
.quotespage .btn {
  padding: 5px 30px;
}
.quotespage .deleteicon {
  max-width: 15px;
  max-height: 15px;
}
.quotespage .dragndrop {
  padding: 20px 15px 5px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background: #eeeeee;
}
.quotespage p.blue {
  color: #000;
  cursor: pointer;
}
.quotespage .after p {
  margin-bottom: 0 !important;
}
.quotespage img.uploading {
  max-width: 25px;
}
.quotespage a {
  color: #000;
}

#colors, #materials, #types, #stones {
  position: relative;
}

#colors {
  z-index: 5;
}

#materials {
  z-index: 4;
}

#types {
  z-index: 3;
}

#stones {
  z-index: 2;
}

.card-body {
  position: relative;
  z-index: 1;
}

#city, #suburb {
  position: relative;
}

#suburb {
  z-index: 3;
}

#city {
  z-index: 2;
}

/* .quotespage .fa.fa-close {
  position: absolute;
  right: 30px;
  top: 10px;
} */
.quotespage .dragndrop .img-wrapper img {
  max-width: 30px;
  max-height: 30px;
  margin-bottom: 10px;
}
.quotespage input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: none;
  border: 1px solid #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quotespage input[type="checkbox"]:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #000;
  font-size: 10px;
  color: #000;
  display: none;
}
.quotespage input[type="checkbox"]:hover {
  background-color: #a5a5a5;
}
.quotespage input[type="checkbox"]:checked {
  background-color: #ffffff;
}
.quotespage input[type="checkbox"]:checked:after {
  display: block;
}
.quotespage label {
  font-family: AvertaRegular;
  color: #000000;
  font-size: 14px;
  line-height: 1.21;
  cursor: pointer;
  margin-bottom: 0;
}
.quotespage .form-control,
.quotespage .dropdownbtn-primary,
.quotespage textarea {
  background-color: #fafafa !important;
  border: 1px solid #fafafa !important;
  border-radius: 0;
  text-align: left;
}
.quotespage .dropdown-toggle::after {
  float: right;
  margin-top: 10px;
}
.quotespage label.heading {
  font-weight: 600;
  margin-bottom: 10px;
}
.quotespage [type="radio"]:checked,
.quotespage [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.quotespage [type="radio"]:checked + label,
.quotespage [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #000000;
}
.quotespage [type="radio"]:checked + label:before,
.quotespage [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #000000;
  border-radius: 100%;
  background: #fff;
}
.quotespage [type="radio"]:checked + label:after,
.quotespage [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #000000;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.quotespage [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.quotespage [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.quotespage textarea {
  width: 100%;
}
.quotespage .btn-light {
  background: transparent;
  color: #000;
  border: 1px solid #000000;
  /* border-radius: 0; */
}
.quotespage .btn-light:hover {
  background-color: #000000;
  color: #ffffff;
}
.btn.focus,
.btn:focus {
  box-shadow: none !important;
}
.quotespage .accordion-button:not(.collapsed):after {
  background-image: url(../../../img/arrow.svg);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.quotespage .accordion-button:after {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url(../../../img/arrow.svg);
  background-repeat: no-repeat;
  background-size: 1.25rem;
  float: right;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.quotespage .homepage a {
  color: #cccccc;
  font-family: AvertaLight;
  font-size: 14px;
  line-height: 17px;
}
.quotespage i.fa.fa-circle {
  font-size: 7px;
  margin: 5px;
}
.content-box h2 {
  font-family: AvertaBold;
  font-size: 50px;
  line-height: 60px;
  color: #000000;
}
.quotespage .btn-browse {
  padding: 5px 20px !important;
}

.quotespage .redast:after {
  content: "*";
  position: absolute;
  top: -15px;
  right: -10px;
  color: red;
  margin-left: 5px;
  font-size: 20px;
}

.quotespage .redast-custom:after {
  content: "*";
  position: absolute;
  top: -15px;
  right: 5px;
  color: red;
  margin-left: 5px;
  font-size: 20px;
}

#province {
  position: relative;
  z-index: 1;
}

.quotespage .btn-browse p {
  margin-bottom: 0 !important;
}

/* Quote Summary Page */

.quote-summary-wrap {
  margin: 150px auto 50px auto;
}

.quote-action-buttons{
  margin: 60px 0 0 0;
  text-align: right;
}

.quote-action-buttons button {
  margin-left: 20px;
}

.quote-summary {
  background: none;
  border: 1px solid #eee;
  padding: 20px 30px;
  margin: 20px 0 30px 0;
}

.quote-table-head{
  font-weight: bold;
}

.quote-summary-wrap .homepage a {
  color: #cccccc;
  font-family: AvertaLight;
  font-size: 14px;
  line-height: 17px;
}

.admin-summary {
  padding: 0 30px;
}

button.quotessummarypage.btn.btn-light {
  background: transparent;
  color: #000;
  border: 1px solid #000000;
}

button.quotessummarypage.btn-light:hover {
  background-color: #000000;
  color: #ffffff;
}


@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .quotespage .hero-section-quotes {
    padding-top: 150px;
  }
}
@media (max-width: 991px) {
}
@media (max-width: 767px) {
}
@media (max-width: 575px) {
  .quotespage h5 {
    font-size: 14px;
  }

  .quotespage h6 span {
    font-size: 16px;
  }

  #headingOne button,
  #headingTwo button,
  #headingThree button,
  #headingFour button
   {
    padding: 0;
    display: flex;
    align-items: center;
  }

  #colors, #materials, #types, #stones {
    position: relative;
  }

  #colors {
    z-index: 5;
  }

  #materials {
    z-index: 4;
  }

  #types {
    z-index: 3;
  }

  #stones {
    z-index: 2;
  }

  .card-body {
    position: relative;
    z-index: 1;
  }

  #city, #suburb {
    position: relative;
  }

  #suburb {
    z-index: 3;
  }

  #city {
    z-index: 2;
  }
}
